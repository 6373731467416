:root {
    --color-black: #1f1f1f;
    --color-white: #fafafa;
    --main-color-primary: #3B556D;
    --main-color-secondary: #5FC2BA;
    --main-color-third: #1C2942;
    --main-color-background: #E3E3E3;
    --sidebar-background: #bbbbbb;
    --sidebar-rooms: #dbdbdb;
    --sidebar-roomselected: #f5f5f5;
    --message-own: #D1D1D1;
    --example: #17191d;
}

.button {
    background-color: var(--main-color-primary);
    color: var(--color-white);
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    min-width: 80px;
    cursor: pointer;
    border: solid;
    border-width: 1px;
    border-color: var(--main-color-primary);
    border-radius: 10px;
    text-align: left;
}

.button:hover {
    background-color: var(--color-white);
    color: var(--main-color-primary);
}

.loginpage {
    height: 100vh;
    width: 100%;
    background-color: var(--main-color-background);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.loginpanel {
    width: 30%;
    height: 40vh;
    margin: auto;
    border-radius: 10px;
    background-color: var(--main-color-third);
    box-shadow: 15px 17px 0px 0px var(--main-color-secondary);
}

.loginpanel h1 {
    color: var(--color-white);
    font-size: x-large;
}

.loginpanel form {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30vh;
    width: 80%;
}

.logininput {
    border: none;
    background-color: var(--main-color-background);
    height: 3vh;
    border-radius: 3px;
    padding: 10px;
}

.loginsubmit {
    cursor: pointer;
    border: none;
    background-color: var(--main-color-primary);
    color: var(--color-white);
    height: 5vh;
    border-radius: 3px;
    padding: 10px;
    font-size: large;
}

.loginsubmit:hover {
    opacity: 0.8;
}

.shadow {
    -webkit-box-shadow: 9px 11px 23px 0px rgba(0,0,0,0.11); 
    box-shadow: 9px 11px 23px 0px rgba(195, 89, 89, 0.11);
    backdrop-filter: blur(5px);
}

.blur {
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.blurbg {
    backdrop-filter: blur(10px);    
}

.scrollPerso::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.scrollPerso::-webkit-scrollbar-track {
    background: #97a2ac6c;
    /* color of the tracking area */
}

.scrollPerso::-webkit-scrollbar-thumb {
    background-color: #3B556D;
    /* color of the scroll thumb */
    /* border-radius: 20px; */
    /* roundness of the scroll thumb */
    border: 3px solid #3B556D;
    /* creates padding around scroll thumb */
}

.scrollPerso2::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.scrollPerso2::-webkit-scrollbar-track {
    background: #1C2942;
    border-radius: 10px;
    /* color of the tracking area */
}

.scrollPerso2::-webkit-scrollbar-thumb {
    background-color: teal;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
    border: 3px solid teal;
    /* creates padding around scroll thumb */
}

.scrollPerso3::-webkit-scrollbar {
    width: 6px;
    /* width of the entire scrollbar */
}

.scrollPerso3::-webkit-scrollbar-track {
    background: #1C2942;
    border-radius: 10px;
    /* color of the tracking area */
}

.scrollPerso3::-webkit-scrollbar-thumb {
    background-color: teal;
    /* color of the scroll thumb */
    border-radius: 5px;
    /* roundness of the scroll thumb */
    border: 1px solid teal;
    /* creates padding around scroll thumb */
}

.scrollPersoHorizontal::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.scrollPersoHorizontal::-webkit-scrollbar-track {
    background: #1C2942;
    border-radius: 10px;
    /* color of the tracking area */
}

.scrollPersoHorizontal::-webkit-scrollbar-thumb {
    background-color: teal;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
    border: 3px solid teal;
    /* creates padding around scroll thumb */
}
